import { useReducer } from 'react';
import { federatedRepoToConnection } from '../../services/support';
import type { Application, FederatedRepository } from '../../types';
import type {
  ShareLinkAction,
  ShareLinkQuery,
  ShareLinkState
} from './reducer';
import {
  ERROR,
  FIND_CONNECTION_END,
  FIND_CONNECTION_INIT,
  shareLinkReducer
} from './reducer';

export function useShareLink(
  defaultApp: Application,
  linkQuery: Partial<ShareLinkQuery>
): [ShareLinkState] {
  const [state, dispatch] = useReducer(shareLinkReducer, {
    status: 'pending',
    loading: true,
    app: defaultApp,
    link: linkQuery
  });

  if (state.status == 'pending') {
    dispatch({ type: FIND_CONNECTION_INIT });
  }

  if (state.status == 'connectionFetch') {
    void fetchConnection(state.link, dispatch);
  }
  return [state];
}

async function fetchConnection(
  { datasource, projectId, workAreaId }: Partial<ShareLinkQuery>,
  dispatch: React.Dispatch<ShareLinkAction>
) {
  if (!datasource || !projectId || !workAreaId) {
    dispatch({ type: ERROR, payload: { message: 'Error' } });
    return;
  }

  const query = `?contextId=${projectId}&workAreaId=${workAreaId}&datasource=${datasource}`;

  try {
    const response = await fetch('/api/share-link/connection' + query, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response.ok) {
      const data = (await response.json()) as FederatedRepository;
      dispatch({
        type: FIND_CONNECTION_END,
        payload: federatedRepoToConnection(data)
      });
    } else {
      dispatch({ type: ERROR, payload: { message: 'Error' } });
    }
  } catch (err) {
    dispatch({ type: ERROR, payload: { message: 'Error' } });
  }
}
