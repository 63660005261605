import './itemSummary.css';
import React, { useMemo } from 'react';
import { itemIsDataItem, itemIsProject } from '@bentley/pw-api';
import { typeSafeTranslate } from '@bentley/pw-i18n';
import { MiddleTextTruncation, Tile } from '@itwin/itwinui-react';
import resources from '../../../resources/languages.json';
import { ItemIcon } from '../itemIcon/itemIcon';
import { formatDateTime, formatFileSize } from './stringFormatter';
/**
 * A ProjectWise item summary tile
 */
export function ItemSummary({ item, useFileName, className }) {
    const items = useMemo(() => {
        if (Array.isArray(item)) {
            return item;
        }
        return [item];
    }, [item]);
    const t = typeSafeTranslate(resources);
    const title = useMemo(() => {
        if (items.length <= 0) {
            return t('icons.noItems');
        }
        if (items.length == 1) {
            return getItemName(items[0], useFileName);
        }
        if (items.length == 2) {
            return `${t('icons.names', {
                name1: getItemName(items[0], useFileName),
                name2: getItemName(items[1], useFileName)
            })}`;
        }
        return `${t('icons.nameAndCount', {
            name: getItemName(items[0], useFileName),
            count: items.length - 1
        })}`;
    }, [items, t, useFileName]);
    function getItemName(item, useFileName) {
        if (!itemIsDataItem(item)) {
            return item.Label || item.Name;
        }
        if (useFileName && item.FileName) {
            return item.FileName;
        }
        return item.Name;
    }
    const description = useMemo(() => {
        if (items.length != 1) {
            return '';
        }
        return items[0].Description;
    }, [items]);
    const itemCountInfo = useMemo(() => {
        if (items.length == 1) {
            return '';
        }
        return t('icons.itemCount', { count: items.length });
    }, [items, t]);
    const folderInfo = useMemo(() => {
        var _a;
        if (items.length != 1) {
            return '';
        }
        const folder = items[0];
        if (!itemIsProject(folder)) {
            return '';
        }
        const owner = folder.Owner
            ? t('icons.ownedDetails', { name: folder.Owner })
            : '';
        const environment = (_a = folder.Environment) !== null && _a !== void 0 ? _a : '';
        const parts = [owner, environment].filter((part) => part.length);
        return parts.join(' | ');
    }, [items, t]);
    const fileInfo = useMemo(() => {
        if (items.length != 1) {
            return '';
        }
        const dataItem = items[0];
        if (!itemIsDataItem(dataItem)) {
            return '';
        }
        const version = dataItem.Version
            ? t('icons.versionDetails', { version: dataItem.Version })
            : '';
        const formattedFileSize = formatFileSize(dataItem.FileSize);
        const parts = [version, formattedFileSize, dataItem.Status].filter((part) => part === null || part === void 0 ? void 0 : part.length);
        return parts.join(' | ');
    }, [items, t]);
    const updatedInfo = useMemo(() => {
        if (items.length != 1) {
            return '';
        }
        const item = items[0];
        const updatedBy = item.UpdatedBy;
        const updateTime = formatDateTime(item.UpdateTime);
        if (!updatedBy || !updateTime) {
            return '';
        }
        return t('icons.updatedDetails', { name: updatedBy, date: updateTime });
    }, [items, t]);
    return (React.createElement(Tile.Wrapper, { variant: "folder", className: `i-s-tile ${className !== null && className !== void 0 ? className : ''}` },
        React.createElement(Tile.ThumbnailArea, { className: "i-s-thumbnail" },
            React.createElement(Tile.ThumbnailPicture, null,
                React.createElement(ItemIcon, { item: item, size: "l" }))),
        React.createElement(Tile.Name, { name: React.createElement(MiddleTextTruncation, { text: title !== null && title !== void 0 ? title : '', style: { whiteSpace: 'pre' } }), className: "i-s-tile-name" }),
        React.createElement(Tile.ContentArea, null,
            React.createElement(Tile.Description, { className: "i-s-desc" }, description !== null && description !== void 0 ? description : ''),
            itemCountInfo && (React.createElement(Tile.Metadata, { className: "i-s-metadata" }, itemCountInfo)),
            folderInfo && (React.createElement(Tile.Metadata, { className: "i-s-metadata" }, folderInfo)),
            fileInfo && (React.createElement(Tile.Metadata, { className: "i-s-metadata" }, fileInfo)),
            updatedInfo && (React.createElement(Tile.Metadata, { className: "i-s-metadata" }, updatedInfo)))));
}
