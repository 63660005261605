import type { ReactNode } from 'react';
import React from 'react';
import { useTernaryDarkMode } from 'usehooks-ts';
import { ThemeProvider } from '@itwin/itwinui-react';

type PWThemeProviderProps = {
  children: ReactNode;
};

export function PWThemeProvider({
  children
}: PWThemeProviderProps): JSX.Element {
  const { ternaryDarkMode } = useTernaryDarkMode();
  const theme = ternaryDarkMode == 'system' ? 'os' : ternaryDarkMode;

  return (
    <ThemeProvider
      theme={theme}
      style={{ height: '100%' }}
      themeOptions={{ applyBackground: true }}
    >
      {children}
    </ThemeProvider>
  );
}
