import type { UniversalQueryProps } from '@bentley/pw-link-ui';
import type { Application, Connection } from '../../types';

export type ShareLinkQuery = {
  objectName: string;
} & UniversalQueryProps;

export type ShareLinkState = {
  status: 'pending' | 'connectionFetch' | 'valid' | 'error';
  app: Application;
  loading: boolean;
  link: Partial<ShareLinkQuery>;
  connection?: Connection;
  error?: {
    message: string;
    status?: number;
  };
};

export const FIND_CONNECTION_INIT = 'FIND_CONNECTION_INIT';
export const FIND_CONNECTION_END = 'FIND_CONNECTION_END';
export const ERROR = 'ERROR';
const SELECT_APP = 'SELECT_APP';

export type ShareLinkAction =
  | { type: typeof FIND_CONNECTION_INIT }
  | { type: typeof FIND_CONNECTION_END; payload: Connection }
  | { type: typeof ERROR; payload: { message: string; status?: number } }
  | { type: typeof SELECT_APP; payload: Application };

export function shareLinkReducer(
  state: ShareLinkState,
  action: ShareLinkAction
): ShareLinkState {
  if (action.type == 'FIND_CONNECTION_INIT') {
    return {
      ...state,
      status: 'connectionFetch',
      loading: true,
      error: undefined
    };
  }
  if (action.type == 'ERROR') {
    return {
      ...state,
      status: 'error',
      loading: false,
      error: action.payload
    };
  }
  if (action.type == 'FIND_CONNECTION_END') {
    return {
      ...state,
      status: 'valid',
      loading: false,
      connection: action.payload,
      error: undefined
    };
  }
  if (action.type == 'SELECT_APP') {
    return {
      ...state,
      app: action.payload
    };
  }
  return state;
}
