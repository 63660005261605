import './shareLink.css';

import React, { useMemo } from 'react';
import type { PWDataItem, PWItem, PWItemClass } from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import type { UniversalQueryProps } from '@bentley/pw-link-ui';
import {
  GetShareableLinkUI,
  getTeamsLinkCore,
  getUniversalLink
} from '@bentley/pw-link-ui';
import { SvgError } from '@itwin/itwinui-illustrations-react';
import { NonIdealState } from '@itwin/itwinui-react';
import { useShareLink } from '../../hooks/useShareLink';
import { t } from '../../services/translation';
import type { Application, SavedSearchDefinition } from '../../types';
import { ErrorFallback } from '../errorFallback';
import { LoadingPage } from '../loadingPage';
import { getLinkProps, getMissingRequiredParams } from './linkParams';

export function ShareLink(): JSX.Element | null {
  const [state] = useShareLink('Explorer', getLinkProps());

  const regionCode = window.location.href.includes('dev-')
    ? '103'
    : window.location.href.includes('qa-')
    ? '102'
    : '101';

  const linkBaseUrl = useBuddi('ProjectWiseWebLinks.URL', regionCode);

  const missingRequiredParams = getMissingRequiredParams(state.link);

  const disabledApplications = useMemo((): Application[] => {
    const disabledApplications = [] as Application[];

    if (!state.connection) {
      disabledApplications.push('Web');
    }

    if (!state.connection?.DefaultTab) {
      disabledApplications.push('Teams');
    }

    if (state.link.objectType == 'saved-search') {
      disabledApplications.push('Explorer');
    }

    return disabledApplications;
  }, [state.connection, state.link.objectType]);

  const item = { Name: state.link.objectName ?? '' } as PWItem;
  if (state.link.objectType == 'saved-search') {
    item.className = 'SavedSearch' as string as PWItemClass;
  } else if (state.link.objectType == 'folder') {
    item.className = 'Project';
  } else {
    item.className = 'Document';
    (item as PWDataItem).FileName = state.link.objectName ?? '';
  }

  function buildLink(
    item: PWItem | SavedSearchDefinition,
    application: Application
  ): string {
    if (application == 'Teams' && state.connection) {
      return getTeamsLinkCore({
        teamsTabLink: state.connection.DefaultTab,
        itemId: state.link.objectId,
        itemType: state.link.objectType
      });
    }

    return getUniversalLink({
      ...state.link,
      application: application,
      datasource: state.link.datasource,
      workAreaId: state.connection?.ProjectId ?? '',
      apiURL: linkBaseUrl,
      shortenLink: false
    } as UniversalQueryProps);
  }

  if (state.loading) {
    return (
      <ErrorFallback>
        <LinkContainer>
          <LoadingPage loadingSubText={t('pwe.resolvingLink')} />
        </LinkContainer>
      </ErrorFallback>
    );
  }

  if (missingRequiredParams.length) {
    return (
      <ErrorFallback>
        <LinkContainer>
          <NonIdealState
            className="error-fallback"
            svg={<SvgError />}
            heading={t('pwe.invalidLink')}
            description={`${t(
              'pwe.missingParameters'
            )}: ${missingRequiredParams.join(', ')}`}
          />
        </LinkContainer>
      </ErrorFallback>
    );
  }

  return (
    <ErrorFallback>
      <LinkContainer>
        <GetShareableLinkUI
          dataAccessLevel="WorkArea"
          disabledApplications={disabledApplications}
          items={[item]}
          useFileName={true}
          getLink={buildLink}
        />
      </LinkContainer>
    </ErrorFallback>
  );
}

function LinkContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="s-l-container" id="share-link-modal">
      {children}
    </div>
  );
}
