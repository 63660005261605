import './errorFallback.css';

import type { ReactNode } from 'react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { SvgError } from '@itwin/itwinui-illustrations-react';
import { NonIdealState } from '@itwin/itwinui-react';
import { t } from '../../services/translation';

type ErrorFallbackProps = {
  children?: ReactNode;
};

export function ErrorFallback({ children }: ErrorFallbackProps): JSX.Element {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        <NonIdealState
          className="error-fallback"
          svg={<SvgError />}
          heading={error?.name ?? 'Error'}
          description={
            <pre>{error?.message ?? error?.cause ?? t('pwe.unknownError')}</pre>
          }
        />
      )}
    >
      {children}
    </ErrorBoundary>
  );
}
