import React from 'react';
import { render } from 'react-dom';
import { AppRoutes } from './appRoutes';

if (window.location.pathname.startsWith('//')) {
  // Double slash fixes issue with PW Explorer routing
  window.location.pathname = window.location.pathname.substring(1);
} else {
  render(<AppRoutes />, document.getElementById('app'));
}
