import type { Connection, FederatedRepository } from '../../types';

/**
 * Maps FederatedRepository to Connection
 * @param {FederatedRepository?} [federatedRepository] Repository to map to Connection
 * @returns {Connection} Mapped Connection object or empty object if unable to map url
 */
export function federatedRepoToConnection(
  federatedRepository?: FederatedRepository
): Connection {
  if (!federatedRepository) {
    return {} as Connection;
  }

  const urlData = splitConnectionUrl(federatedRepository.Url);

  return {
    Name: federatedRepository.Name,
    Type: federatedRepository.Type,
    Description: federatedRepository.Description,
    DisplayLabel: federatedRepository.DisplayLabel,
    ServerUrl: urlData.serverUrl,
    DatasourceInstanceId: urlData.datasourceId,
    ConnectionUrl: urlData.connectionUrl,
    Id: federatedRepository.Id,
    ProjectId: urlData.projectId,
    WorkAreaName: federatedRepository.WorkAreaName,
    DefaultTab: federatedRepository.DefaultTab,
    Canned: federatedRepository.Canned ?? false
  };
}

type SplitConnectionData = {
  projectId: string;
  datasourceId: string;
  serverUrl: string;
  connectionUrl: string;
  wsgVersion: string;
};

function splitConnectionUrl(url: string): SplitConnectionData {
  // wac connection: https://server/ws/v2.8/repositories/datasourceInstanceId/PW_WSG/Project/projectId
  // ds connection:  https://server/ws/v2.8/repositories/datasourceInstanceId
  // server url can contain additional parts separated by '/'
  // finds relevant parts of connection Url by working with constants in the url we control like the term repositories and PW_WSG schema
  const containsWorkArea = url.toLocaleLowerCase().includes('pw_wsg/project');
  const repoUrlParts = url.split('/');

  const projectId = containsWorkArea
    ? repoUrlParts[repoUrlParts.length - 1]
    : '';

  const datasourceIdPosition = containsWorkArea
    ? repoUrlParts.length - 4
    : repoUrlParts.length - 1;

  const datasourceId = repoUrlParts[datasourceIdPosition];

  const repositoryStringPosition = repoUrlParts.findIndex((item) => {
    return item.toLocaleLowerCase() == 'repositories';
  });

  const version =
    repositoryStringPosition > 1
      ? repoUrlParts[repositoryStringPosition - 1]
      : '';

  let serverUrl = repoUrlParts.slice(0, repositoryStringPosition).join('/');

  serverUrl += '/';

  const schemaNamePosition = repoUrlParts.findIndex((item) => {
    return item.toLocaleLowerCase() == 'pw_wsg';
  });

  const connectionUrl = containsWorkArea
    ? repoUrlParts.slice(0, schemaNamePosition).join('/')
    : url;

  return {
    projectId: projectId,
    datasourceId: datasourceId,
    serverUrl: serverUrl,
    connectionUrl: connectionUrl,
    wsgVersion: version
  };
}
